<!--
 * @Author: yanzq
 * @Date: 2021-12-17 23:50:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-08 18:51:00
 * @Description: 请填写简介
-->
<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <div class="module-box">
      <div class="module-item">
        <div>
          <span class="count">{{ count }}</span>
          <span class="icon-count">家</span>
        </div>
        <p class="title" style="margin-top: 10px">绑定企业</p>
        <p class="second-title">Binding enterprise</p>
        <button @click="goT">前往 ></button>
      </div>
      <div class="module-item" v-if="isFa">
        <div>
          <img src="../../assets/images/userCenter/img1.png" alt="" />
        </div>
        <p class="title" style="margin-top: 10px">授权管理</p>
        <p class="second-title">Management authorization</p>
        <button
          @click="goS"
          style="box-shadow: 0 2px 13px 0 #e98900; color: rgba(255, 162, 0, 1)"
        >
          前往 >
        </button>
      </div>

      <div class="module-item">
        <div>
          <img src="../../assets/images/userCenter/c.png" alt="" />
        </div>
        <p class="title" style="margin-top: 10px">登记信息复核</p>
        <p class="second-title">Perfect information</p>
        <button
          @click="goI"
          style="box-shadow: 0 2px 13px 0 #e98900; color: rgba(255, 162, 0, 1)"
        >
          前往 >
        </button>
      </div>
    </div>
    <van-dialog
      v-model="show"
      :title="this.linkTitle"
      @confirm="confirmBd"
      class="title"
    >
      <van-field
        v-model="name"
        label="姓名"
        placeholder="请输入姓名"
        required
      />
      <van-field
        type="digit"
        v-model="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
      />
    </van-dialog>
    <!-- <van-cell>
      <template #title>绑定企业</template>
      <template #right-icon></template>
    </van-cell>
    <van-cell v-if="isFa">
      <template #title>授权管理</template>
    </van-cell> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getWechatEnterpriseSupervisionCount,
  geOrtUser,
  getTokenUnionID,
  getUser,
  saveAccountByGroup,
  getOpenId,
} from "../../api/home";
import service from "../../utils/request";
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      count: "",
      show1: true,
      src: "",
      phone: "",
      name: "",
      isFa: false,
      show: false,
      waAccountName: "",
      waAccountPhone: "",
      linkTitle: "",
    };
  },
  created() {},
  mounted() {
    localStorage.removeItem("waShxydm");
    localStorage.removeItem("username");
    localStorage.removeItem("waGroup");
    // localStorage.removeItem("userInfo");
    localStorage.removeItem("userImg");
    this.showPopup();
    this.getShare();
  },

  methods: {
    ...mapActions(["commitSetGlobalInfo"]),
    confirmBd() {
      let url = decodeURIComponent(window.location.href);
      // url =
      //   '"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=https://qtqyzx.qiantang.gov.cn/#/userCenter?isShare=true&waShxydm=92330100MA2ACW5H3X&waGroup=2&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"';
      let search = url.split("?");
      const openid = localStorage.getItem("openid");
      const waShxydm = this.GetQueryString("waShxydm", search[1] + search[2]);
      const waGroup = this.GetQueryString(
        "waGroup",
        search[1] + search[2]
      ).split("#")[0];
      const waCompanyName = this.GetQueryString(
        "waCompanyName",
        search[1] + search[2]
      );

      if (!openid) {
        this.$toast("请稍候...");
        return false;
      }

      if (!this.phone) {
        this.$dialog.alert({
          title: "提示",
          message: "请填写手机号",
        });
        return false;
      }
      if (!this.name) {
        this.$dialog.alert({
          title: "提示",
          message: "请填写姓名",
        });
        return false;
      }
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.phone.replace(/\s+/g, "").length == 0 ||
        isNaN(Number(this.phone)) ||
        !reg_tel.test(this.phone)
      ) {
        Toast.fail("请输入正确手机号");
      }
      getWechatEnterpriseSupervisionCount({
        waOpenId: openid,
        waBind: "1",
      }).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item, index) => {
            if (item.waShxydm == waShxydm) {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "你已是该企业法人无法绑定群组",
                })
                .then(() => {
                  wx.closeWindow();
                });
            } else {
              saveAccountByGroup({
                waShxydm,
                waCompanyName,
                waAccountName: this.name,
                waOpenId: openid,
                waAccountPhone: this.phone,
                waGroup,
                waHeadImg: this.$store.state.globalInfo.headimgurl,
              }).then((res) => {
                // const that = this;
                if (res.success) {
                  this.show = false;
                  // this.data = res.data || {};
                  this.commitSetGlobalInfo(
                    Object.assign(this.$store.state.globalInfo, {
                      nickname: this.name,
                    })
                  );
                  Toast.success("绑定成功");
                  let time = 3;
                  let timer = setInterval(() => {
                    if (time) {
                      this.$dialog.alert({
                        message: `${time}秒后自动关闭`,
                        showConfirmButton: false,
                      });
                      time--;
                    } else {
                      clearInterval(timer);
                      wx.closeWindow();
                    }
                  }, 1000);
                } else {
                  Toast.success(res.actionMessages[0]);
                }
              });
            }
          });
        } else {
          saveAccountByGroup({
            waShxydm,
            waCompanyName,
            waAccountName: this.name,
            waOpenId: openid,
            waAccountPhone: this.phone,
            waGroup,
            waHeadImg: this.$store.state.globalInfo.headimgurl,
          }).then((res) => {
            // const that = this;
            if (res.success) {
              this.show = false;
              // this.data = res.data || {};
              this.commitSetGlobalInfo(
                Object.assign(this.$store.state.globalInfo, {
                  nickname: this.name,
                })
              );
              Toast.success("绑定成功");
              let time = 3;
              let timer = setInterval(() => {
                if (time) {
                  this.$dialog.alert({
                    message: `${time}秒后自动关闭`,
                    showConfirmButton: false,
                  });
                  time--;
                } else {
                  clearInterval(timer);
                  wx.closeWindow();
                }
              }, 1000);
            } else {
              Toast.success(res.actionMessages[0]);
            }
          });
        }
      });
      // checkUserIn({
      //   waOpenId: openid,
      //   waAccountPhone: this.phone,
      //   waShxydm,
      //   waGroup,
      //   name: this.name,
      // })
      //   .then((res) => {
      //     console.log(res, "绑定");
      //     if (res.success) {
      //       this.show = false;
      //       this.$toast.success("绑定成功");
      //     } else {
      //       this.$toast.fail("绑定失败");
      //     }
      //   })
      //   .catch(() => {
      //     this.$toast.fail("绑定失败");
      //   });
    },
    // 删除url中某个参数,并跳转
    funcUrlDel(name) {
      var loca = window.location;
      var baseUrl = loca.origin + loca.pathname + "?";
      var query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        var obj = {};
        var arr = query.split("&");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        var url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, "")
            .replace(/\:/g, "=")
            .replace(/\,/g, "&");
        return url;
      }
    },
    getShare() {
      let url = decodeURIComponent(window.location.href);
      let searchUrl = url.split("?");
      let wx_code = this.GetQueryString("code", searchUrl[1] + searchUrl[2]);
      // url =
      //   '"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=https://qtqyzx.qiantang.gov.cn/#/userCenter?isShare=true&waShxydm=92330100MA2ACW5H3X&waGroup=2&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"';
      let search = decodeURIComponent(url).split("?")[2];
      let isShare = this.GetQueryString("isShare", searchUrl[1] + searchUrl[2]);
      let waGroupArr = ["政府事务联络人", "知识产权联络人", "安全管理员"];
      let name = this.GetQueryString("name", searchUrl[1] + searchUrl[2]);
      let waCompanyName = this.GetQueryString(
        "waCompanyName",
        searchUrl[1] + searchUrl[2]
      );
      let waGroup = this.GetQueryString("waGroup", searchUrl[1] + searchUrl[2]);
      this.linkTitle = `${name}邀请你绑定${waCompanyName}${
        waGroupArr[parseInt(waGroup) - 1]
      }群组`;

      let openid = localStorage.getItem("openid");
      // if (wx_code) {
      //   if (isShare && isShare == "true") {
      //     this.getIndent(openid);
      //     console.log(window.location.href, "32");
      //   }
      // }
      //根据UnionID机制获取用户基本信息;
      getTokenUnionID().then((access_token) => {
        let wx_code = this.GetQueryString("code");
        if (wx_code) {
          if (access_token) {
            geOrtUser({
              access_token,
              openid: localStorage.getItem("openid"),
              lang: "zh_CN",
            }).then((res) => {
              // console.log(res, "用户信息");
              if (res.subscribe == 0) {
                this.$dialog
                  .alert({
                    title: "提示",
                    message: "还没关注公众号，请前往关注",
                  })
                  .then(() => {
                    wx.closeWindow();
                  });
              } else {
                if (isShare && isShare == "true") {
                  this.getIndent(openid);
                }
              }
            });
          }
        }
      });
    },
    getIndent(openid) {
      // console.log("进来了");
      getWechatEnterpriseSupervisionCount({
        waOpenId: openid,
        waBind: "1",
      }).then((res) => {
        if (res.data && res.data.length) {
          this.isFa = true;
          // this.$dialog
          //   .alert({
          //     title: "提示",
          //     message: "您是法人,不能绑定",
          //   })
          //   .then(() => {
          //     wx.closeWindow();
          //   });
        }
        // if (!this.isFa) {
        // }
        this.show = true;
      });
    },
    GetQueryString(name, search) {
      search =
        search ||
        window.location.search.substr(1) ||
        window.location.hash.split("?")[1];
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      if (!search) return null;
      let r = search.match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getUrlQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    goT() {
      this.$router.push({
        path: "/bindList",
      });
    },
    goS() {
      this.$router.push({
        path: "/authorizationList",
      });
    },
    goI() {
      this.$router.push({
        path: "/infoList",
      });
    },
    showPopup() {
      const openid = localStorage.getItem("openid");
      getWechatEnterpriseSupervisionCount({
        waOpenId: openid,
        waBind: "1",
      }).then((res) => {
        this.count = res.data.length || 0;
        if (res.data && res.data.length) {
          this.isFa = true;
          this.commitSetGlobalInfo(
            Object.assign(this.$store.state.globalInfo, {
              nickname: res.data[0].waAccountName,
            })
          );
          // sessionStorage.setItem("username", res.data[0].waAccountName);
        } else {
          // this.username = sessionStorage.getItem("username");
        }
      });
    },
    backLevel(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    backHome(e) {
      e.preventDefault();
      // this.$router.push({ path: "/" });
      wx.closeWindow();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 rem(20);
}
.module-box {
  background: #fafdff;
  border-radius: 32px 32px 0 0;
  padding: 20px 10px;
  width: 100%;
  position: relative;
  top: -40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .module-item {
    padding-top: rem(60);
    padding-left: rem(34);
    width: 49%;
    height: rem(402);
    background: url("../../assets/images/userCenter/m2.png");
    background-size: 100% 100%;
    font-size: 14px;
    color: black;
  }
  & > .module-item:nth-child(2) {
    background: url("../../assets/images/userCenter/m1.png");
    background-size: 100% 100%;
  }
  & > .module-item:last-child {
    background: url("../../assets/images/userCenter/c1.png");
    background-size: 100% 100%;
  }
  .icon-count {
    width: rem(54);
    height: rem(34);
    display: inline-block;
    background: #ffffff;
    box-shadow: 0 2px 13px 0 rgba(23, 79, 190, 0.52);
    border-radius: rem(50) rem(32) rem(32) 0;

    font-size: rem(24);
    color: #2f8ff6;
    text-align: center;
    position: relative;
    top: rem(-8);
    left: rem(10);
  }
  .count {
    font-weight: Bold;
    font-size: rem(96);
    color: #ffffff;
    margin-top: rem(50);
  }
  .title {
    font-weight: bolder;
    font-size: rem(32);
    color: #ffffff;
  }
  .second-title {
    font-size: rem(24);
    color: rgba(255, 255, 255, 0.7);
    margin: rem(15) 0;
  }
  button {
    width: rem(96);
    height: rem(34);
    border: none;
    background: #ffffff;
    box-shadow: 0 rem(2) rem(14) 0 rgba(23, 79, 190, 0.52);
    border-radius: rem(16);
    font-size: rem(24);
    margin-top: rem(8);
    color: rgba(47, 143, 246, 1);
  }
  img {
    width: rem(84);
    height: rem(84);
    margin-top: rem(20);
  }
}
.banner {
  padding: rem(20);
  width: 100%;
  height: rem(362);

  background: url("../../assets/images/userCenter/banner-bg.png");
  background-size: 100%;
  color: white;
  h3 {
    font-size: rem(48);
  }
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(340);
    height: rem(216);
  }
}
// .van-cell {

//   padding-top: rem(30);
//   padding-bottom: rem(30);
//   span {
//     font-family: DIN-Bold;
//     font-weight: Bold;
//     font-size: rem(56);
//     color: #3296fa;
//     vertical-align: middle;
//     margin-right: rem(10);
//   }
// }
.van-cell img {
  width: rem(64);
  vertical-align: middle;
  height: rem(64);
}
</style>
